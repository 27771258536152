const ynTripList = [
  {
    name: "昆明丨丽江丨大理 6天5晚",
    prize: 0,
    linkName: "",
    typeName:'昆明丨丽江丨大理 6天5晚',
    type:'6天5晚',
    typeList:[{
        typeName:'6天5晚'
    }],
    smallImages:'http://dspcloud.spay365.com/98a1a4cdbae87f3b03041ad90a079e8036f27aeb.jpg',
    // orderDetialImage:'http://dspcloud.spay365.com/f9d117d33f0b781522bfb6f4e9328d25ce646755.png',
    bannerList: [
      {
        imgUrl:
          "http://dspcloud.spay365.com/1e077ec3bc1dd834e5aa2d9e62094cd562dc2a5a.png",
      },
      {
        imgUrl:
          "http://dspcloud.spay365.com/0a79ad8f65b57f57c5e6d390730db89c479670aa.png",
      },
      {
        imgUrl:
          "http://dspcloud.spay365.com/ad62c9b3bb3542e3534390b89c46c8d64bbc75fc.png",
      },
      {
        imgUrl:
          "http://dspcloud.spay365.com/b6236b9cf0ad1a7d1453d31d2eac1ae0764c7ef4.png",
      },
    ],
    detailImage: "http://dspcloud.spay365.com/8a3d2b83632d9317a5338ea374f00d8fb21dc7a3.jpg",
    productNo:'GPBOjEhThi'
  },
  {
    name: "昆明丨芒市丨瑞丽丨腾冲 6天5晚",
    prize: 0,
    linkName: "KMRT",
    type:'6天5晚',
    typeName:'昆明丨芒市丨瑞丽丨腾冲 6天5晚',
    productNo:'ANuqybwYza',
    smallImages:'http://dspcloud.spay365.com/0ab040885c3a53f60769b4ce873a367f9a8523be.jpg',
    typeList:[{
        typeName:'6天5晚'
    }],
    bannerList: [
      {
        imgUrl:
          "http://dspcloud.spay365.com/516cc2d10edba1955a7ac41da771066bd8468998.png",
      }, {
        imgUrl:
          "http://dspcloud.spay365.com/10e050f49d13619950ab59e318a6f47441e67413.png",
      }, {
        imgUrl:
          "http://dspcloud.spay365.com/a01b4c76c7d9fc223272bfd9bdedc0d2e0914678.png",
      },{
        imgUrl:
          "http://dspcloud.spay365.com/676671d8d029eebe06a0f264b077da12d62e837f.png",
      }
    ],
    detailImage: "http://dspcloud.spay365.com/370b604a53d6dae4b39a339cee6100b333cfa969.jpg",
  },
  {
    name: "昆明丨西双版纳 6天5晚",
    prize: 0,
    type:'6天5晚',
    linkName: "KMRT",
    typeName:'昆明丨西双版纳 6天5晚',
    productNo:'qCzUZdZEuw',
    typeList:[{
        typeName:'6天5晚'
    }],
    smallImages:'http://dspcloud.spay365.com/82f31095158b3f7a22c1c2fa80bdbc247be87288.jpg',
    bannerList: [
      {
        imgUrl:
          "http://dspcloud.spay365.com/0fd98262df4fe38facf13968c85cb075ca74bef9.png",
      },
      {
        imgUrl:
          "http://dspcloud.spay365.com/cd6182f7721411ba4acd1c825544607325abf190.png",
      },
      {
        imgUrl:
          "http://dspcloud.spay365.com/d9283cbf367bf86fca22626576e38da728eff081.png",
      },{
        imgUrl:
          "http://dspcloud.spay365.com/c6b25cbe96fe7b37038038ed036914300db7244b.png",
      }
    ],
    detailImage: "http://dspcloud.spay365.com/4179270ea33476351ffdab5c3fc5a8eaafe0bf72.jpg",
  },
  {
    name: "昆明丨大理丨丽江丨泸沽湖 8天7晚",
    prize: 0,
    linkName: "KDLL",
    type:'8天7晚',
    typeName:'昆明丨大理丨丽江丨泸沽湖 8天7晚',
    productNo:'YyYgkqxmdO',
    typeList:[{
        typeName:'8天7晚'
    }],
    smallImages:'http://dspcloud.spay365.com/a30d2ff7caf78a54b5729a16e4722aee739bc438.jpg',
    bannerList: [
      {
        imgUrl:"http://dspcloud.spay365.com/56eb954b89f1d6302cf1146fd6534157c466897c.png",
      },
      {
        imgUrl:"http://dspcloud.spay365.com/ddd41c66ab4a19415cdbdcf08ea963d9a581e0d5.png",
      },
       {
        imgUrl:"http://dspcloud.spay365.com/626145c7bb1e0745c227491b7c71159f9bad15b7.png",
      },
      {
        imgUrl:"http://dspcloud.spay365.com/5650685f72d9c7e919a0a0e5c3a7ec289919335b.png",
      }
    ],
    detailImage: "http://dspcloud.spay365.com/5a9cac4389dc06a4a1209d33371cfe626b17a664.jpg",
  },
  {
    name: "昆明丨大理丨丽江丨香格里拉 8天7晚",
    prize: 0,
    linkName: "KDLX",
    type:'8天7晚',
    typeName:'昆明丨大理丨丽江丨香格里拉 8天7晚',
    productNo:'QGjFukkjDb',
    typeList:[{
        typeName:'8天7晚'
    }],
    smallImages:'http://dspcloud.spay365.com/383411068f286694dfa7d0608da411bec9b9bebf.jpg',
    bannerList: [
      {
        imgUrl:
          "http://dspcloud.spay365.com/0f7a98aaedd4e5051efe727bbbbd35892b27b537.png",
      },
      {
        imgUrl:
          "http://dspcloud.spay365.com/a3181da007e679ad06b0212c312ba45941744b71.png",
      },
      {
        imgUrl:
          "http://dspcloud.spay365.com/2cd4dbf17398d20dde584d0d2e207133a057a35a.png",
      },
      {
        imgUrl:
          "http://dspcloud.spay365.com/7e7740b280f3710a9f8b1a0b5008fac40fc9d77a.png",
      }
    ],
    detailImage: "http://dspcloud.spay365.com/e98891433beb81e8247dd80924b3a92ba165105d.jpg",
  },
 {
    name: "昆明丨大理丨丽江丨香格里拉丨泸沽湖 10天9晚",
    prize: 0,
    linkName: "KDLXL",
    type:'10天9晚',
    typeName:'昆明丨大理丨丽江丨香格里拉丨泸沽湖 10天9晚',
    productNo:'YTtjlxftir',
    typeList:[{
        typeName:'10天9晚'
    }],
    smallImages:'http://dspcloud.spay365.com/df45562e818d9479c80e866c5135762a31443874.jpg',
    bannerList: [
      {
        imgUrl:
          "http://dspcloud.spay365.com/c9336664380a829016fa50f65a1c9611bb2bf24b.png",
      },
      {
        imgUrl:
          "http://dspcloud.spay365.com/467278f659d1f2564fcd55e0d84dd18f19f71e6a.png",
      },
      {
        imgUrl:
          "http://dspcloud.spay365.com/2baf093f63585c3b0ddd8290678be94127391f7f.png",
      },
      {
        imgUrl:
          "http://dspcloud.spay365.com/a426a063a1628bca66169af6dda9b7932df4c06e.png",
      }
    ],
    detailImage: "http://dspcloud.spay365.com/9abf53b51de2d85feedba5df423c74b4e2190f4b.jpg",
  }
]

export default ynTripList;
//6款产品都在